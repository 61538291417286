.offers-detail-container {
    margin: 0 auto;
    padding: 0 3% 0;

    .order-palier-list {
        font-size: 16px;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 15px;
            font-size: 14px;
            &::before {
                content: "";
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #27b9ac;
                display: inline-block;
                margin-bottom: 3px;
                margin-right: 7px;
            }
        }
    }

    .offers-content {
        @include flexb(row, center, flex-start);
        gap: 20px;

        .sob-card {
            flex-basis: 70%;
            flex-grow: 1;
        }

        .offer-detail-rightside {
            min-width: 300px;
            max-width: 400px;
            flex-grow: 1;
        }

        .sob-audit-card {
            margin: 0;
            margin-bottom: 20px;

            .sob-audit-card-body {
                padding: 10px 0;

                .sob-btn-custom {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    align-items: center;
                    margin-bottom: 10px;

                    .CustomButton__sob-icon-container {
                        height: 70px;
                        width: 68px;
                        @include flexb;
                        padding: 0;

                        img {
                            display: block;
                        }
                    }

                    .CustomButton__sob-text {
                        padding: 0;
                        margin: 0;
                        margin-left: 10px;
                        max-width: 120px;
                    }

                    .CustomButton__sob-arrow-container {
                        position: relative;
                        transform: none;
                        margin-left: auto;
                    }
                }
            }
        }
    }

    .btn-detail {
        @include flexb;
        gap: 10px;
        background-color: transparent;
        border: 1px solid #27b9ac;
        border-radius: 8px;
        height: 45px;
        color: #27b9ac;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: rgba(0, 0, 0, 0.055);
        }
    }

    .btn-detail-wide {
        width: 100%;
        font-size: 14px;
        margin: 10px 0;
    }

    .offers-detail-header {
        @include flexb(row, space-between);
        min-height: 80px;
    }

    .sob-card {
        margin: 0;
        padding: 3%;

        .offers-detail-title {
            @include flexb(row, flex-start);
            margin: 10px 0 20px 10px;
            width: 100%;
            font-size: 14px;
            font-weight: 400;

            h2 {
                margin: 0;
                margin-right: 50px;
                font-size: 14px;
                font-weight: 600;
            }

            div {
                @include flexb;
                img {
                    display: block;
                    margin-right: 10px;
                }
            }
        }

        .custom-offer-row {
            @include flexb(row, space-between, flex-start);
            width: 100%;

            .sob-row {
                width: 100%;
                margin: 0;
                flex-wrap: nowrap;

                .sob-col {
                    flex-basis: auto;

                    .sob-card-item-value {
                        font-size: 14px;
                    }
                }
            }
        }

        .offers-detail-img {
            border-radius: 11px;
            max-width: 380px;
            max-height: 220px;
            width: 100%;
            height: 100%;
            display: block;
        }

        .sob-card-item-value {
            @include flexb(row, flex-start);
            gap: 10px;

            img {
                width: 27px;
                object-fit: contain;
            }
        }

        .offer-item-special {
            @include flexb(row, flex-start);
            gap: 10px;

            .offer-item-special-info {
                @include flexb(column, center, flex-start);

                p {
                    font-size: 14px;
                    font-weight: 300;
                    margin: 0;
                }
                strong {
                    font-size: 13px;
                    font-weight: 600;
                }
            }

            em {
                font-size: 14px;
                font-weight: 700;
                font-style: normal;
                padding: 10px;
                color: #f05a29;
                background-color: #f05b2910;
                text-align: center;
            }
        }

        .order-important-text {
            em {
                color: #f05a29;
                font-size: 18px;
                font-weight: 500;
            }

            color: #f05a29;
            font-size: 14px;
            font-weight: 300;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .table-responsive {
            overflow: visible;
        }

        .order-table {
            td {
                font-size: 14px;
            }

            tbody {
                border: none;
                display: block;
                min-height: 20px;

                &:first-of-type {
                    min-height: 0;
                }
            }

            .single-prod {
                display: table-row-group;
            }

            .commande-pack {
                display: table-row-group;
                border: 1px solid #27b9ac;
                background-color: #f5fdfd;

                tr {
                    background-color: transparent;
                }
            }

            .order-img-container {
                position: relative;
                background-color: white;
                box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.062);
                width: 66px;
                height: 67px;
                border-radius: 9px;
                @include flexb;

                .order-img {
                    max-width: 100%;
                    max-height: 100%;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .order-zoom-in {
                    @include flexb;
                    position: absolute;
                    visibility: hidden;
                    background-color: transparent;
                    border-radius: 9px;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    img {
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                    }
                }

                &:hover {
                    // .order-img {
                    //     opacity: 0.5;
                    // }

                    .order-zoom-in {
                        visibility: visible;
                        background-color: rgba(0, 0, 0, 0.37);

                        img {
                            opacity: 1;
                        }
                    }
                }
            }

            .order-important {
                color: #f05a29;
                font-size: 18px;
                font-weight: 500;
            }
        }

        .order-card-container {
            padding: 1rem;
        }

        .order-payment-container {
            margin-left: auto;
            margin-right: 30px;
            margin-bottom: 20px;
            max-width: 500px;
            width: 100%;
            font-size: 14px;

            .order-payment-row {
                @include flexb(row, space-between);
                padding: 10px 10px;
                margin: 5px 0;
                border-radius: 8px;
            }

            .payment-special-row {
                color: #079700;
                background-color: #0897000e;

                strong {
                    font-weight: 700;
                }
            }

            .payment-total-row {
                background-color: #f9f9f9;
                strong {
                    font-weight: 700;
                }
            }
        }
    }

    .order-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .order-card-table {
        background-color: white;
    }

    .order-finished-header {
        @include flexb(column);

        .order-finished-icon {
            margin-bottom: 20px;
        }

        .order-finished-text {
            font-size: 18px;
        }

        a {
            text-decoration: none;
            color: #27b9ac;
        }
    }

    .order-finished-btns {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        // justify-items: center;
        // justify-content: center;
        max-width: 600px;
        margin: 50px auto;
        padding-left: 50px;

        .sob-btn-custom {
            padding-right: 50px;

            .CustomButton__sob-icon-container {
                width: 70px;
            }
        }
    }

    .offers-cards-v {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
        margin-top: 20px;
        padding: 10px;

        @media screen and (min-width: 1400px) {
            grid-template-columns: repeat(5, 1fr);
        }

        .offers-card {
            border-radius: 16px;
            border: 1px solid #e2e2e2;
            max-width: 300px;
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.048);

            .offers-card-header {
                border-radius: 16px;
                img {
                    border-radius: 16px;
                    display: block;
                    width: 100%;
                }
            }

            .offers-card-body {
                .offers-card-body-highlight {
                    @include flexb(row, space-between);

                    p {
                        font-size: 14px;
                        font-weight: 500;
                        margin: 0;
                        width: 100%;
                        text-align: center;
                    }

                    em {
                        font-size: 14px;
                        font-weight: 700;
                        font-style: normal;
                        padding: 10px;
                        color: #f05a29;
                        background-color: #f05b2910;
                        width: 100%;
                        text-align: center;
                    }
                }

                .offers-card-body-info {
                    padding: 10px;
                }
            }
        }
    }
}
