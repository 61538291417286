html {
    margin: 0;
    padding: 0;
    /* overflow-y: scroll; */
    /* min-height: 100vh; */
}

body {
    margin: 0;
    padding: 0;
    display: block;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    font-family: "Poppins";
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ReactModal__Overlay {
    z-index: 99999;
}
