.order-details-container {
    .order-payment-container {
        margin-left: auto;
        margin-right: 30px;
        margin-bottom: 20px;
        max-width: 500px;
        width: 100%;
        font-size: 15px;

        .order-payment-row {
            @include flexb(row, space-between);
            padding: 10px 10px;
            margin: 5px 0;
            border-radius: 8px;
        }

        .payment-special-row {
            color: #079700;
            background-color: #0897000e;

            strong {
                font-weight: 700;
            }
        }

        .payment-total-row {
            background-color: #f9f9f9;
            strong {
                font-weight: 700;
            }
        }
    }
}
.order-details {
    .order-important {
        color: #f05a29;
        font-size: 18px;
        font-weight: 500;
    }

    .order-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .sob-row {
        margin: 0;
    }

    .order-card-container {
        padding: 1rem;
    }

    .order-payment-container {
        margin-left: auto;
        margin-right: 30px;
        margin-bottom: 20px;
        max-width: 500px;
        width: 100%;
        font-size: 15px;

        .order-payment-row {
            @include flexb(row, space-between);
            padding: 10px 10px;
            margin: 5px 0;
            border-radius: 8px;
        }

        .payment-special-row {
            color: #079700;
            background-color: #0897000e;

            strong {
                font-weight: 700;
            }
        }

        .payment-total-row {
            background-color: #f9f9f9;
            strong {
                font-weight: 700;
            }
        }
    }

    .order-table {
        td {
            font-size: 14px;
        }

        .order-img-container {
            position: relative;
            background-color: white;
            box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.062);
            width: 66px;
            height: 67px;
            border-radius: 9px;
            @include flexb;

            .order-img {
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            .order-zoom-in {
                @include flexb;
                position: absolute;
                visibility: hidden;
                background-color: transparent;
                border-radius: 9px;
                height: 100%;
                width: 100%;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                img {
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                }
            }

            &:hover {
                // .order-img {
                //     opacity: 0.5;
                // }

                .order-zoom-in {
                    visibility: visible;
                    background-color: rgba(0, 0, 0, 0.37);

                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .sob-audit-card {
        .sob-audit-card-body {
            padding: 10px 0;

            .sob-btn-custom {
                padding: 0;
                margin: 0;
                width: 100%;
                align-items: center;
                margin-bottom: 10px;

                .CustomButton__sob-icon-container {
                    height: 70px;
                    width: 68px;
                    @include flexb;
                    padding: 0;

                    img {
                        display: block;
                    }
                }

                .CustomButton__sob-text {
                    padding: 0;
                    margin: 0;
                    margin-left: 10px;
                    max-width: 120px;
                }

                .CustomButton__sob-arrow-container {
                    position: relative;
                    transform: none;
                    margin-left: auto;
                }
            }
        }
    }
}
