.offers-list-header {
    @include flexb(row, space-between);
    border-radius: 16px;
    padding: 0 16px;
    margin-bottom: 20px;

    &[label="yellow"] {
        border-top: 1px solid #e0a43a;
    }

    &[label="green"] {
        border-top: 1px solid #03bf9c;
    }

    &[label="blue"] {
        border-top: 1px solid #45bfea;
    }

    &[label="orange"] {
        border-top: 1px solid #f86d00;
    }

    .offers-list-header_timeLeft {
        margin-left: auto;
        margin-right: 20px;
        @include flexb;
        width: 200px;

        img {
            display: block;
            margin-right: 9px;
        }
    }

    .offers-type {
        margin-top: -3px;
        @include flexb(row, flex-start);
        gap: 10px;
        padding: 15px;
        border-radius: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        &[label="yellow"] {
            color: #e0a43a;
            border-top: 3px solid #e0a43a;
            background-color: #e0a33a0c;
        }

        &[label="green"] {
            color: #03bf9c;
            border-top: 3px solid #03bf9c;
            background-color: #03bf9c0c;
        }

        &[label="blue"] {
            color: #45bfea;
            border-top: 3px solid #45bfea;
            background-color: #45bfea0c;
        }

        &[label="orange"] {
            color: #f86d00;
            border-top: 3px solid #f86d00;
            background-color: #f86d000c;
        }
    }

    .show-active {
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
            margin: 8px 10px 0 !important;
        }
    }
}
