.suggProd-container {
    padding-top: 0;

    .suggProd-header {
        @include flexb(row, space-between);
        min-height: 80px;
    }

    .sob-card {
        margin: 0;
    }
}
