.supplier-container {
    .supplier-header {
        @include flexb(row, space-between);
        min-height: 80px;

        a {
            text-decoration: none;
        }
    }
}

.supplier-detail-container {
    margin: 0 auto;
    padding: 0 3% 0;

    .supplier-detail-header {
        @include flexb(row, space-between);
        min-height: 80px;
    }

    .supplier-detail-body {
        @include flexb(row, center, flex-start);
        gap: 20px;
        .sob-card {
            margin: 0;
            padding: 3%;

            .sob-card-item-title {
                span {
                    font-weight: 400;
                }
            }
        }

        .sob-audit-card {
            margin: 0;
            max-width: 400px;
        }
    }
}
