.event-detail-container {
    .event-detail-body {
        @include flexb(row, center, flex-start);
        gap: 20px;

        .event-detail-main {
            flex-grow: 1;
        }

        h4 {
            font-size: 19px;
            font-weight: 600;
            color: #1bb9ac;
            margin: 20px 0;
        }

        h2 {
            font-size: 22px;
            font-weight: 600;
        }

        .btn {
            margin: 0;
        }

        .sob-card {
            margin: 0;
            margin-bottom: 20px;

            .event-banner {
                object-fit: contain;
                // max-width: 700px;
                max-width: 100%;

                width: 100%;
                height: 100%;
                max-height: 250px;
                border-radius: 16px;
            }

            .event-detail-btn-grp {
                @include flexb(row, flex-start);
                gap: 10px;
                margin-top: 20px;
            }
        }

        .sob-tabs-container {
            .event-detail-tab {
                .event-detail-tab-card {
                    @include flexb(row, flex-start, flex-start);
                    gap: 10px;
                    margin-bottom: 10px;

                    .card-img {
                        min-height: 25px;
                        min-width: 30px;
                        @include flexb;

                        img {
                            display: block;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }

        .event-detail-sidebar {
            max-width: 350px;
            .sob-audit-card {
                min-width: 300px;
                margin: 0;
                margin-bottom: 20px;

                .sob-audit-card-body {
                    padding: 10px 0;
                }

                .myEvent-card {
                    @include flexb(row, flex-start, flex-start);
                    gap: 10px;
                    margin-top: 10px;

                    .myEvent-calender {
                        width: 75px;
                        height: 64px;
                        background-color: #1bb9ac0e;
                        border-radius: 11px;
                        flex-shrink: 0;
                    }

                    .myEvent-body {
                        .myEvent-date {
                            @include flexb(row, flex-start);
                            gap: 5px;
                            font-size: 13px;
                        }

                        .myEvent-desc {
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
