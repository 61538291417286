.events-container {
    .events-header {
        @include flexb(row, space-between);
        min-height: 80px;

        a {
            text-decoration: none;
        }
    }

    .events-container-body {
        @include flexb(row, center, flex-start);
        gap: 20px;

        .events-cards {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
            gap: 20px;

            .event-row {
                box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.082);
                border-radius: 16px;
                padding: 20px;
                transition: all 0.2s ease-out;
                display: grid;
                grid-template-rows: 1fr 1fr 0.5fr;
                max-height: 400px;
                cursor: pointer;

                .event-head {
                    background-color: #1bb9ac0e;
                    width: 113px;
                    height: 120px;
                    border-radius: 16px;
                    @include flexb(column);
                    font-size: 23px;
                    font-weight: 300;

                    h1 {
                        font-size: 45px;
                        font-weight: 600;
                    }
                }

                .event-body {
                    display: grid;
                    grid-template-rows: 0.5fr 1fr 1fr;

                    .event-type {
                        color: #a5a5a5;
                        font-size: 14px;
                        margin: 0;
                    }

                    .event-title {
                        font-weight: 600;
                        font-size: 18px;
                        margin-bottom: 10px;
                        transition: all 0.2s ease-in;
                    }

                    .event-info {
                        align-self: end;

                        .event-info-card {
                            @include flexb(row, flex-start);
                            font-size: 14px;

                            span {
                                width: 25px;
                                margin-right: 10px;

                                img {
                                    display: block;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }

                .event-footer {
                    margin-top: 20px;

                    .sob-btn {
                        width: 100%;
                        justify-content: center;
                    }
                }

                &:hover {
                    box-shadow: 0 13px 56px 0px rgba(0, 0, 0, 0.116);
                    // transform: scaleY(1.03);

                    .event-title {
                        color: #1bb9ac;
                    }
                }
            }
        }

        .sob-audit-card {
            margin: 0;
            max-width: 300px;
        }
    }
}

@keyframes btnFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100;
    }
}
