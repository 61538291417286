.groups-container {
    .groups-header {
        @include flexb(row, space-between);
        min-height: 80px;

        a {
            text-decoration: none;
        }
    }
}
