.accessDenied-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    background-color: white;
    z-index: 9999;

    display: grid;
    grid-template-columns: 1fr 1fr;

    .accessDenied-content {
        justify-self: center;
        align-self: center;
        @include flexb(column, flex-start, flex-start);
        gap: 20px;

        h1 {
            color: #f22a50;
            font-size: 55px;
            font-weight: 600;
        }

        h4 {
            font-size: 38px;
            font-weight: 400;
        }

        .sob-btn {
            margin: 0;
            background-color: #f22a50;
            padding: 0.7rem 2rem;
            width: 150px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #aa1d37;
            }
        }
    }

    .accessDenied-img {
        img {
            display: block;
            object-fit: cover;
            width: 100%;
            height: 100vh;
        }
    }
}
