.page404-container {
    width: 100%;
    height: 100%;
    min-height: 70vh;
    margin: 50px 0;
    @include flexb(column);
    flex-wrap: nowrap;

    .h2 {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 300;
        color: #1bb9ac;
    }

    .btn {
        background-color: #1bb9ac;
        margin-top: 20px;

        &:hover {
            background-color: #18a79b;
        }
    }
}
