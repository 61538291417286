html {
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
    }

    &::-webkit-scrollbar-track-piece {
        background: #1bb9ac0e;
    }
    &::-webkit-scrollbar-thumb {
        background: #4ab3aa93;
        border-radius: 5px;
    }

    min-width: 1100px;
}

.fade {
    animation: fade 1s ease-out;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

:root {
    --sob-secondary: #f0fcfd !important;
    --sob-secondary-text-color: #18b1d4 !important;
}

.ReactModal__Overlay {
    z-index: 9999;
}

.sob-btn {
    // border-radius: 16px !important;
    height: 45px !important;
    // padding: 10px 25px !important;
}

.sob-form-group {
    .sob-label {
        // margin-bottom: 0px;
    }

    .sob-form-control {
        // min-height: 54px;
        height: 40px;
    }

    // margin-bottom: 20px;
    position: relative;

    // .sob-invalid-feedback {
    //     position: absolute;
    //     bottom: -20px;
    //     left: 0;
    // }
}

.react-datepicker-popper {
    z-index: 99;
}

.sob-table-pagination {
    .sob-btn {
        height: 45px !important;
        padding: 10px 10px !important;
        border-radius: 10px !important;
    }
}

.Calendar__TooltipEvent-footer {
    display: none !important;
}

.palier-btn {
    border-radius: 8px !important;
    height: 45px !important;
}

.NavSubMenu__container {
    z-index: 999 !important;
    overflow: inherit !important;
}

.NavSubMenu__item-container {
    padding-left: 0;
    padding-right: 0;
    cursor: pointer !important;
    width: 100%;
    height: 50px;
}

.NavSubMenu__item {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: solid 1px #f0f0f0 !important;
    height: 100%;
    width: 100%;
}

.NavSubMenu__link {
    padding: 10px;
    font-family: Poppins !important;
    font-size: 0.75rem !important;
    text-align: left !important;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.NavSubMenu__link:hover {
    color: var(--sob-primary) !important;
}

.navbar {
    z-index: 999 !important;
}

.nav-item-more-hide {
    opacity: 0 !important;
    z-index: -1 !important;
}

.nav-item-more-show {
    opacity: 1 !important;
    z-index: 1 !important;
}

.nav-item-more-sub-menu {
    padding-left: 0 !important;
}

.nav-item-more-sub-menu-item {
    display: block !important;
    width: 100% !important;
    height: 44px !important;
}

// .nav-item a.active,
// .nav-link:hover {
//     background-color: white;
// }

.nav-item-more-sub-menu-item .nav-link {
    height: 44px !important;
}

.dropdown-menu-container {
    top: 15px !important;

    .dropdown-menu {
        cursor: pointer;
    }
}

.navbar-user-infos {
    padding: 0.5rem;
    width: fit-content;
}

.navbar-user-infos > svg {
    margin: 0 !important;
}

.navbar-user-name {
    flex-shrink: 0;
    max-width: 100px;
    text-align: left;
}

.TablePage__btn,
.TablePage {
    background-color: #f4fcfd;
    color: #02829f;
    font-weight: 600;
    font-size: 16px;
}

.TablePage__btn:hover,
.TablePage:hover {
    background-color: #ddfbff;
    color: #02829f;
}

.suggest-modal {
    overflow: visible;
}

.sob-btn-custom {
    margin-bottom: 20px;

    .CustomButton__sob-icon-container {
        width: 40px !important;
        height: 40px !important;
        padding: 8px !important;

        img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .CustomButton__sob-text {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        max-width: 100% !important;
    }

    .CustomButton__sob-arrow-container {
        width: 40px !important;
        height: 40px !important;
    }
}

.StatisticalCard__container {
    min-height: 76px !important;

    & > div:first-child {
        flex-basis: 30%;
    }

    & > div:nth-child(2) {
        flex-basis: 70%;
        align-items: flex-start;
        margin-left: 10px;
    }

    .StatisticalCard__number {
        font-size: 25px !important;
        font-weight: 500 !important;
    }

    &:last-of-type {
        margin-bottom: 0 !important;
    }
}

.react-datepicker-wrapper {
    .react-datepicker__aria-live {
        display: none;
    }
}

.internal-offers-actions-content {
    .internal-offers-actions--actions {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 12px;
        cursor: pointer;
        margin: 5px 0;
    }

    .react-icons-wrapper {
        width: 30px;
        height: 30px;
        background-color: #f0fcfd;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 30px;
    }
}

.sob-select__menu-list {
    .sob-select__option--is-disabled {
        color: rgba(6, 6, 6, 0.45) !important;
    }
}
