.balanceRep-container {
    .rapport-datepicker {
        width: auto !important;

        .react-datepicker__aria-live {
            display: none;
        }
    }

    .rapport-detail-number {
        cursor: pointer;
        &:hover {
            color: #1bb9ac;
        }
    }

    .fd-btns {
        .btn {
            background-color: #1bb9ac;
            color: white;

            &:hover {
                background-color: #159489 !important;
            }
        }
    }

    .fd-detail-cards {
        width: 100%;
    }
    .sob-card {
    }
}
