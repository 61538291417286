.dashbord-container {
    .dashbord-header {
        @include flexb(row, space-between);
        min-height: 80px;
    }

    .dashbord-body {
        display: grid;
        grid-template-columns: minmax(250px, 25%) 1fr minmax(250px, 25%);
        gap: 20px;

        .dashboard-posts {
            @include flexb(column);
            gap: 20px;
            flex-grow: 1;

            & > div {
                width: 100%;
            }

            .Card_container {
                flex-shrink: 1;
                margin-bottom: 20px;
                max-width: 800px;
                width: 100%;

                .card_content_image_container > img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .Card_container {
            min-height: 400px;
        }

        .dashb-actions {
            background-color: white;
            padding: 1rem;
            border-radius: 1rem;
            height: fit-content;

            .dashb-actions-header {
                @include flexb(row, flex-start);
                gap: 10px;
            }

            .dashb-actions-body {
                margin-top: 20px;

                .sob-btn-custom {
                    margin-bottom: 20px;

                    &:last-of-type {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        .dashb-statistics {
            background-color: white;
            padding: 1rem;
            border-radius: 1rem;
            height: fit-content;

            .StatisticalCard__container {
                margin: 0;
                margin-bottom: 20px;
            }
        }
    }
}
