.step-container {
    @include flexb;
    gap: 20px;
    margin: 20px auto 50px;

    .step-card {
        @include flexb;
        gap: 10px;

        .step-number {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #707070;
            @include flexb;
            font-size: 20px;
            font-weight: 500;
        }

        svg {
            margin-left: 10px;
        }
    }

    .step-card-active {
        .step-number {
            color: white;
            background-color: #27b9ac;
            border-color: #27b9ac;

            svg {
                margin: 0;
            }
        }

        .step-text {
            color: #27b9ac;
        }

        svg {
            path {
                fill: #27b9ac;
            }
        }
    }
}
