.fidny {
    // Helpers
    @import "./helpers/Variables";
    @import "./helpers/Mixins";
    @import "./basics/Buttons";

    position: relative;
    background-color: #f4f4f4;
    min-height: 100vh;
    // height: 100%;
    width: 100%;
    padding-bottom: 100px;
    // display: block;

    * {
        font-family: "Poppins";
        box-sizing: border-box;
    }

    .disabled-input {
        .sob-input-select__control,
        .sob-select__control {
            background-color: rgb(226, 226, 226);
            pointer-events: none;
        }
    }

    .sob-megaNavBar-container .sob-megaNavBar-items .sob-megaNavBar-item > a {
        margin-top: 0;
        padding-top: 13px;
    }

    .loader {
        min-height: 100vh;
        @include flexb;
    }

    .sob-table-search {
        &::before {
            visibility: hidden;
        }
    }

    .table thead th {
        vertical-align: middle;
    }

    .table-actions {
        img {
            margin-right: 10px;
            cursor: pointer;

            &:hover {
                filter: brightness(0.9);
            }
        }
    }

    .sob-table-header-title {
        white-space: pre;
    }

    .table-newData-loader {
        margin: 0 10px;
        width: 100%;
    }

    .fd-container {
        margin: 0 auto;
        padding: 0 25px;

        .fd-header {
            @include flexb(row, space-between);
            min-height: 80px;

            a {
                text-decoration: none;
            }
        }

        .fd-table {
            width: 100%;
            background-color: white;
            border-radius: 1rem;
            padding: 20px;

            .sob-table-header {
                padding-top: 0;
            }
        }
    }

    .fd-btns {
        @include flexb;
        flex-shrink: 0;

        a {
            text-decoration: none;
        }

        .sob-btn {
            flex-shrink: 0;
            margin: 0;
            margin-left: 20px;
        }
    }

    .fd-detail-container {
        padding: 0 25px 50px;

        .fd-detail-header {
            @include flexb(row, space-between);
            min-height: 80px;
        }

        .fd-detail-body {
            @include flexb(row, center, flex-start);
            gap: 20px;

            .fd-details-tabs {
                .tabPanel {
                    border-radius: 11px;
                    border-top-left-radius: 0;

                    .MuiBox-root {
                        border-radius: 11px;
                    }
                }
            }

            .fd-detail-content {
                // width: 80%;
                flex-basis: 70%;
                border-radius: 16px;
                flex-grow: 1;
            }

            .fd-detail-status {
                min-width: 300px;
                max-width: 400px;
                flex-grow: 1;
            }

            .sob-audit-card {
                margin: 0;
                margin-bottom: 20px;
            }
        }
    }

    .fd-create-container {
        padding: 0 25px 50px;

        .fd-create-header {
            @include flexb(row, space-between);
            min-height: 80px;
        }

        .sob-card {
            margin: 0;
        }
    }

    .table-tag {
        // height: 45px;
        // min-height: 45px;
        @include flexb;
        // font-size: 13px;
        // font-weight: 500;
        // padding: 10px;
        width: 100px;
    }

    .tag-wide {
        width: 220px;
    }

    .tag-medium {
        width: 140px;
    }

    .small-tag-v2 {
        padding: 5px 10px;
        height: 20px;
        min-height: 20px;
        width: fit-content;
    }

    .sob-form-control {
        min-height: 54px;
    }

    .basic-select {
        min-height: 54px;
    }

    .wrapper-editor {
        border: 1px solid #c6c6c6;
        border-radius: 16px;
        min-height: 250px;
        padding: 1rem;

        .editor-container {
            min-height: 200px;
            cursor: text;
        }
    }

    @import "./pages/Nav";
    @import "./pages/dashboard/TableauDeBord";
    @import "./pages/products/Products";
    @import "./pages/products/SuggestProduct";
    @import "./pages/fournisseurs/Fournisseurs";
    @import "./pages/fournisseurs/SuggestFourni";
    @import "./pages/orders/OrderDetails";
    @import "./pages/orders/OrderCreation";
    @import "./pages/offers/Offers";
    @import "./pages/offers/OfferCreation";
    @import "./pages/offers/OffersDetail";
    @import "./pages/offers/OffersTable";
    @import "./pages/events/Events";
    @import "./pages/events/EventsDetails";
    @import "./pages/events/NewEvent";
    @import "./pages/groupes/Groupes";
    @import "./pages/groupes/GroupeDetails";
    @import "./pages/reports/BalanceRep";
    @import "./pages/Settings";
    @import "./pages/Signin";
    @import "./pages/AccessDenied";
    @import "./pages/Page404";

    @import "../components/offers/OffersListHeader";
    @import "../components/offers/Steps";
}
