.settings-container {
    @include flexb(row, center, flex-start);
    gap: 20px;
    position: relative;
    z-index: 0;

    .labs-settings-btns {
        position: absolute;
        top: -72px;
        right: 0;

        a {
            text-decoration: none;
        }
    }

    .sob-accordion__panel_item {
        display: flex;
        width: 100%;
        text-decoration: none;

        &:visited {
            color: inherit;
        }

        color: black;

        &:hover {
            color: #18b1d4;
        }
    }

    .active {
        color: #00acd2 !important;
    }

    .settings-accord-container {
        background-color: white;
        padding: 20px 12px;
        border-radius: 10px;
        width: 300px;
        min-width: 250px;
    }

    .settings-content {
        flex-grow: 1;
        // background-color: white;
        border-radius: 1rem;
        min-height: 400px;
        height: 100%;
        // padding: 20px;
        position: relative;

        .user-edit-container {
            .sob-card {
                padding: 0;
                margin: 0;
            }
        }

        .labs-table {
            padding: 0;

            .sob-table-header {
                padding: 0;
            }
        }

        .sob-doctor-card {
            margin: 0;
            border: none;
            padding-left: 0;
        }

        .email-in-container {
            h5 {
                font-size: 22px;
                font-weight: 600;
            }

            .email-in-body {
                @include flexb(column);
                gap: 20px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        .email-in-exist {
            margin-top: 20px;

            p {
                font-size: 15px;
                font-weight: 300;

                img {
                    margin-right: 10px;
                    margin-bottom: -2px;
                }

                i {
                    color: #00acd2;
                    font-style: normal;
                }
            }
        }

        tbody {
            .checkbox {
                pointer-events: none;
            }
        }

        .roles-cards {
            .roles-card {
                @include flexb(row, space-between);
                gap: 10px;
                padding: 10px;
                border: 1px solid #efefef;
                border-radius: 10px;
                margin-bottom: 10px;

                .roles-btns {
                    @include flexb;

                    img {
                        display: block;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
