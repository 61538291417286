.signin-wrapper {
    background: rgb(255, 255, 255);
    background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(27, 185, 172, 1) 50%);
    background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(27, 185, 172, 1) 50%);
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, rgba(27, 185, 172, 1) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1bb9ac",GradientType=1);

    position: fixed;
    height: 100%;
    width: 100%;
    @include flexb(column);

    .signin-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include flexb(row, space-between);
        flex-wrap: wrap;
        gap: 20px;
        padding: 20px 3%;

        img {
            max-width: 148px;
        }
    }

    .signin-container {
        max-width: 400px;
        width: 100%;
        padding: 30px 5%;
        @include flexb(column);
        background-color: white;
        box-shadow: 0 0 40px 20px rgba(109, 109, 109, 0.151);
        border-radius: 16px;

        img {
            margin-bottom: 20px;
            max-width: 140px;
            margin-left: -10px;
        }

        .sob-btn {
            max-width: 300px;
            width: 100%;
            margin: 0;
            padding: 10px 30px;
            background-color: #1bb9ac;

            &:hover {
                background-color: #19a398;
            }
        }
    }
}
