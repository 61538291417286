@mixin flexb($dir: row, $justify: center, $items: center) {
    display: flex;
    justify-content: $justify;
    align-items: $items;
    flex-direction: $dir;
}

@mixin gridlayout($boxSize: 320px, $rpt: 2) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax($boxSize, 1fr));
    grid-template-rows: repeat($rpt, 1fr);
}

@mixin imgOptimise {
    will-change: transform;
    image-rendering: -webkit-optimize-contrast;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}
