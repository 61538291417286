.newEvent-container {
    padding-top: 0;

    .newEvent-header {
        @include flexb(row, space-between);
        min-height: 80px;
    }

    .sob-card {
        margin: 0;
    }

    .newEvent-uppercard {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .newEvent-lowercard {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .newEvent-card-btn {
        & > div:first-child {
            pointer-events: none;
        }

        .rug-item {
            input,
            select {
                display: none;
            }
        }
    }
}
